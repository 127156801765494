#values-section {
    background-color: var(--white);
}

section.main-section {
    display: flex;

    align-items: center;
    justify-content: center;
}

section.main-section>div {
    max-width: 1200px;
    width: 90%;
    margin: 10rem auto;
    padding: 0 2rem;
}

.values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    width: 100%;
    padding: 0 1rem;
}

.section-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.section-title {
    font-weight: 400;
    line-height: 48px;
    font-size: 48px;
    color: var(--green);
    font-family: var(--main-font);
    text-transform: uppercase;
    text-align: center;
}

.section-desc {
    font-weight: lighter;
    font-size: 16px;
    line-height: 24px;
    max-width: 950px;
    text-align: center;
}

.section-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 75px;
}

.value-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: calc(100% /3);
}

.value-number {
    line-height: 24px;
    font-size: 38px;
    font-weight: normal;
    color: var(--green);
    font-family: var(--main-font);
}

.value-title {
    line-height: 24px;
    font-size: 24px;
    font-weight: normal;
    color: var(--pink);
    font-family: var(--main-font);
    text-transform: uppercase;
}

.value-item .section-desc {
    line-height: 20px;
}

@media only screen and (max-width: 776px) {
    .section-content {
        flex-wrap: wrap;
    }

    .value-item {
        max-width: 40%;
        width: auto;
    }
}

@media only screen and (max-width: 557px) {
    .section-content {
        flex-direction: column;
        align-items: center;
    }

    .value-item {
        max-width: 100%;
        width: 100%;
    }

    .value-item .section-desc {
        max-width: 250px;
    }
}