.scrolling-mouse {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.scrolling-mouse p {
    font-size: 16px;
    color: var(--green);
    font-weight: bold;
}

.mouse {
    width: 37px;
    height: 60px;
    border: 2px solid var(--green);
    border-radius: 30px;
    position: relative;
    z-index: -1;
}

.mouse::after {
    content: "";
    position: absolute;
    left: 17px;
    top: 13px;
    height: 12px;
    border: 2px solid var(--green);
    border-radius: 30px;
}