.projects {
    max-width: 1500px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
}

.projects .section-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.projects .project-item {
    width: 50%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.project-title {
    font-weight: normal;
    line-height: 24px;
    color: var(--pink);
    font-family: var(--main-font);
}

.project-desc {
    font-weight: lighter;
    line-height: 20px;
}

.project-image {
    display: flex;
}

.project-image img {
    width: 100%;
}

@media (max-width: 1420px) {
    .projects .project-item {
        width: 45%;
        max-width: 100%;
    }
}

@media (max-width: 830px) {
    .projects .project-item {
        width: 100%;
        max-width: 100%;
    }
}