.transformation {
    padding: 0 0 5rem 0 !important;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

@media (max-width:557px) {
    .transformation .section-title {
        font-size: 30px;
        line-height: 30px;
    }
}