@font-face {
  font-family: Impact;
  src: url('./fontsAssets/Impact/impact.ttf')
}

@font-face {
  font-family: BAHNSCHRIFT;
  src: url('./fontsAssets/BAHNSCHRIFT/BAHNSCHRIFT.TTF')
}

@font-face {
  font-family: Deutschlands;
  src: url('./fontsAssets/deutschlands/deutschlands-lg495.ttf')
}

body {
  --black: #000000;
  --ash-black: #222;
  --white: #ffffff;
  --sky: #00ccff;
  --green: #155a56;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #d85754;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;

  --primary: #155A56;
  --secondary: #D85754;
  --dark-secondary: #BC3836;

  --text-dark: var(--black);

  --green-background: var(--green);
  --pink-background: var(--pink);
  --accent: var(--white);

  --main-font: Impact;
  --body-font: BAHNSCHRIFT;
  --special-font: Deutschlands;

  margin: 0;

  font-family: var(--body-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}