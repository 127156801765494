h1 {
  font-size: 200px;
}

h2 {
  font-size: 124px;
}

h3 {
  font-size: 76px;
}

h4 {
  font-size: 48px;
}

h5 {
  font-size: 30px;
}

h6 {
  font-size: 18px;
}

body {
  font-size: 16px;
  background-color: white;
}

.button {
  background-color: var(--pink-background);
  font-family: var(--pink-background);
  padding: 15px 35px;
  color: var(--white);
  border-radius: 0px 10px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.button:hover {
  background-color: #BC3836;
}

.header-line {
  background-color: var(--pink);
  height: 70px;
  width: 2.5px;
  border: none;
}

html {
  scroll-behavior: smooth;
}