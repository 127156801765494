#services-section {
    position: relative;
    width: 100%;
    padding: 5rem 0;
}

.services-img-2100,
.services-img-1725,
.services-img-1280,
.services-img-830,
.services-img-430,
.services-img-600 {
    position: absolute;
    z-index: -1;
    overflow: hidden;
    width: 100%;
}

.services-img-1725,
.services-img-1280,
.services-img-830,
.services-img-430,
.services-img-600 {
    display: none;
}

#services-section>div {
    margin: 5rem auto;
}

.services,
.services .section-title {
    color: var(--white) !important;
}

.services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.services-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.services-list {
    width: 60%;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.services-list>li {
    width: 47%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.services-title {
    font-family: var(--main-font);
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
}

.services-desc {
    font-weight: lighter;
    text-align: center;
}

.services-video {
    width: 40%;
}

.video {
    max-height: 100%;
    width: 100%;
}

.services-video video {
    width: auto;
    height: 700px;
}

@media (min-width: 2000px) {
    .services-img-2100 {
        display: none;
    }

    #services-section {
        background-color: var(--green);
    }
}

@media (max-width: 1725px) {
    .services-img-1725 {
        display: block;
    }

    .services-img-2100 {
        display: none;
    }
}

@media (max-width: 1200px) {
    .services-img-1280 {
        display: block;
    }

    .services-img-1725 {
        display: none;
    }

    .services-list {
        width: 70%;
    }

    .services-video {
        width: 30%;
    }

    .services-video video {
        height: 500px;
    }
}

@media (max-width: 992px) {
    .services-list {
        width: 100%;
    }

    .services-video {
        display: none;
    }

    .services-list {
        gap: 2rem;
    }

    .services .section-header {
        gap: 1rem;
    }
}

@media (max-width: 776px) {
    .services-img-1280 {
        display: none;
    }

    .services-img-830 {
        display: block;
    }
}

@media (max-width: 600px) {
    .services-img-830 {
        display: none;
    }

    .services-img-600 {
        display: block;
    }

    .services-list>li {
        width: 100%;
        max-width: 400px;
    }

    .services {
        gap: 4rem;
    }
}

@media (max-width: 430px) {
    .services-img-600 {
        display: none;
    }

    .services-img-430 {
        display: block;
    }

    .services .section-header {
        gap: 2rem;
    }
}