#call-to-action {
    margin-bottom: 5rem;
}

.call-to-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    margin-top: 3rem !important;
}

.call-to-action .section-header {
    gap: 40px;
}

.call-to-action .section-title {
    font-size: 84px;
    line-height: 84px;
    text-align: center;
}

.call-to-action .section-subtitle {
    font-size: 30px;
    line-height: 48px;
}

@media (max-width:776px) {
    .call-to-action {
        margin: 2rem auto !important;
    }
}

@media (max-width:557px) {
    .call-to-action .section-title {
        font-size: 48px;
        line-height: 48px;
    }
}