.footer {
    position: relative;
    width: 100%;
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
}

.footer-bg {
    position: absolute;
    right: 0;
    z-index: -1;
    overflow: hidden;
}

.footer-upper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
}

.footer-upper .footer-section {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.footer-title {
    font-size: 30px;
    font-family: var(--main-font);
    font-weight: lighter;
    text-transform: uppercase;
}

.footer-item {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.footer-item h6 {
    font-size: 24px;
    font-weight: lighter;
}

.footer-item p {
    font-size: 16px;
    font-weight: lighter;
    color: #ffffff90;
}

.footer-icons {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.footer-icons .icon {
    font-size: 40px;
}

.footer-links {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.footer-links li {
    font-size: 24px;
    font-weight: lighter;
}

.footer-lower {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 95%;
    max-width: 1500px;
    margin: 2rem auto;
}

.footer-copyright {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

@media (min-width: 1800px) {
    .footer-upper {
        margin: 0 0 0 auto;
    }

    .footer-lower {
        margin: 2rem 0rem 2rem auto;
        padding-right: 2rem;
        box-sizing: border-box;
    }
}

@media (max-width: 1076px) {
    .footer-upper .footer-section {
        width: calc(100% /3);
    }

    .footer-upper {
        width: 90%;
        gap: 1rem;
        box-sizing: border-box;
    }
}

@media (max-width:840px) {
    .footer-lower {
        flex-direction: column;
        margin: 0.5rem auto;
        gap: 10px;
    }

    .footer-lower>img {
        width: 250px;
    }

    .footer-lower>p {
        align-self: flex-end;
    }
}

@media (max-width:776px) {
    .footer {
        padding: 9rem 0 0 0;
        gap: 0;
    }

    .footer-bg {
        right: 0;
        top: -5%;
    }

    .footer {
        overflow-x: hidden;
    }

    .footer-section {
        gap: 20px !important;
    }

    .footer-upper {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;
        background-color: var(--pink);
        margin: 0;
        width: 100%;
    }

    .footer-lower {
        background-color: var(--pink);
        width: 100%;
        margin: 0;
        padding: 2rem;
        box-sizing: border-box;
        gap: 50px;
    }

    .footer-upper .footer-section:first-of-type {
        width: 100%;
        align-items: center;
    }

    .footer-upper .footer-section {
        padding: 0 1rem;
    }

    .footer-copyright {
        font-size: 16px;
    }
}

@media (max-width:400px) {

    .footer-upper .footer-section {
        padding: 0 1rem;
        width: 100%;
        align-items: flex-start !important;
    }

    .footer-lower {
        background-color: var(--pink);
        width: 100%;
        margin: 0;
        padding: 2rem;
        box-sizing: border-box;
    }

    .footer-title {
        font-size: 24px;
    }

    .footer-title,
    .footer-icons {
        align-self: center;
    }

    .footer-links {
        align-items: center;
        align-self: center;
        gap: 15px;
    }

    .footer-links li,
    .footer-item h6 {
        font-size: 20px;
    }

    .footer-item {
        align-items: center;
        align-self: center;
    }

    .footer-item p {
        font-size: 12px;

    }
}