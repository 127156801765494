#banner {
    display: flex;
    align-items: center;
    height: 90vh;
}

.banner-content {
    max-width: 1000px;
    margin-left: 150px;
    padding-left: 2rem;
    margin-top: 10vh;
}

.banner-heading {
    font-size: 76px;
    line-height: 76px;
    color: var(--green);
    font-family: var(--main-font);
    font-weight: normal;
    padding-bottom: 2rem;
}

.banner-heading span {
    font-size: 94px;
    color: var(--pink);
    font-family: var(--special-font);
    font-weight: normal;
}

.banner-subheading {
    font-size: 30px;
    line-height: 30px;
    color: var(--green);
    font-stretch: condensed;
}

.banner-subheading span {
    font-size: 48px;
    line-height: 48px;
    color: var(--pink);
    font-family: var(--special-font);

}

@media (max-width: 1024px) {
    .banner-heading {
        font-size: 58px;
    }

    .banner-heading span {
        font-size: 76px;
    }
}

@media (max-width: 557px) {
    #banner {
        display: flex;
        align-items: flex-end;
        padding-bottom: calc(4rem - 10vh);
    }

    .banner-content {
        margin: 0 auto;
        padding-bottom: calc(10rem - 10vh);
    }
}

@media (max-width: 448px) {
    .banner-heading {
        font-size: 48px;
        line-height: 56px;
    }

    .banner-heading span {
        font-size: 56px;
    }
}