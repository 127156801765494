/* #full_screen { */
    /* height: 100vh; */
    /* overflow-y: scroll; */
    /* scroll-snap-type: y mandatory; */
/* } */

#full_screen .section {
    /* scroll-snap-align: start; */
    background-color: var(--pink);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* scroll-snap-align: center; */
    /* perspective: 500px; */
}

#full_screen .section>div {
    width: 90%;
    position: relative;
    max-height: 90vh;
    max-width: 1200px;
    overflow: hidden;
}

#full_screen p {
    color: var(--white);
    font-size: 30px;
    line-height: 48px;
    font-weight: normal;
}

#full_screen span {
    color: var(--green);
    font-family: var(--special-font);
    font-size: 48px;
}

.marketera-name {
    font-weight: bold;
    text-transform: uppercase;
    font-family: var(--body-font) !important;
    font-size: 30px !important;
}