.banner-img {
    position: fixed;
    top: 0;
    left: 0;
    width: 150px;
    height: 100vh;
    background-image: url("../../Assets/Hero_banner_section.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
}

@media (max-width: 557px) {
    .banner-img {
        background-image: url("../../Assets/banner-mobile.png");
        width: 40%;
        background-position: center center;
        left: 25%;
        top: -15%;
    }
}