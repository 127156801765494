.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 60px;
    z-index: 1000;
    cursor: pointer;
}

.scroll-icon {
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease;
}

.scroll-icon:hover {
    background-color: #555;
}