#statistics-section {
    background-color: var(--green);
}

#statistics-section>div {
    margin: 5rem auto;
    padding: 0;
}

.statistics {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
    color: var(--white);
}

.statistics-line {
    background-color: var(--white);
    border: none;
    width: 2px;
    height: 100px;
}

.statistics-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
}

.statistics-item h4 {
    font-weight: bolder;
}

.statistics-item p {
    font-size: 18px;
}

@media only screen and (max-width: 1067px) {
    .statistics {
        flex-wrap: wrap;
        gap: 50px;
    }
}

@media only screen and (max-width: 557px) {
    .statistics-line {
        display: none;
    }
}