.navbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem auto;
    width: 90%;
    max-width: 1500px;
}


.menu {
    display: flex;
    align-items: center;
    gap: 30px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.menu-item a{
    padding: 15px 10px;
    color: var(--primary);
    border-radius: 0px 10px;
    border: none;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.button {
    padding: 10px 20px;
    font-size: 20px;
}

.navbar_social_media {
    display: flex;
    align-items: center;
    gap: 30px;

}

.navbar_social_media a {
    color: var(--primary);
    text-decoration: none;
}

/* Responsive styles */
@media(min-width:777px) {
    .navbar_social_media {
        display: none;
    }

    .menu-logo {
        display: none;
    }
}

@media (max-width: 776px) {
    .menu {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 0;
        background-color: white;
        border: none;
        box-shadow: none;
        z-index: 10;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transform: translateY(-100%);
    }

    .menu-item {
        padding: 20px;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #ddd;
    }

    .menu-logo {
        margin-bottom: 65px;
        width: 300px;
        height: 100px;
    }

    .hamburger {
        display: block;
        cursor: pointer;
        padding: 15px;
        z-index: 20;
        position: fixed;
        top: 20px;
        right: 15px;
    }

    .hamburger svg {
        color: var(--primary);
    }
}

.hamburger {
    display: none;
}

@media (max-width: 776px) {
    .hamburger {
        display: block;
    }
}

.menu.active {
    opacity: 1;
    transform: translateY(0);
}

@media (max-width:500px) {
    .menu-logo {
        width: 240px;
        height: 50px;
    }
}