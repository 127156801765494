.swiper_container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 475px;
    height: 100%;
    width: 100%;
    background-color: transparent !important;
    position: relative;
}

.mySwiper {
    background-color: transparent !important;
    min-height: 433px;
}

.swiper {
    height: 100%;
    width: 100%;
    background-color: transparent !important;
}


.swiper-slide {
    text-align: center;
    font-size: 18px;
    background-color: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.swiper-slide img {
    display: block;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 14px 20px 0 #00000025, 0 6px 20px 0 rgba(0, 0, 0, 0.25);
}

.iPhone_15_Pro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 240px;
    height: 475px;
    z-index: 0;
    background-color: transparent !important;
}

.Swiper_Slide_Image {
    width: 100%;
    height: auto;
    object-fit: contain;
}

@media(max-width:1280px) {
    .iPhone_15_Pro {
        width: 235px;
        height: 480px;
        top: 46%;
    }

    .Swiper_Slide_Image {
        width: 100%;
    }
}

@media(max-width:1024px) {
    .iPhone_15_Pro {
        width: 250px;
        height: 490px;
    }

    .Swiper_Slide_Image {
        width: 100%;
    }
}

@media(max-width:912px) {
    .iPhone_15_Pro {
        width: 310px;
        height: 490px;
    }


}

@media(max-width:853px) {
    .iPhone_15_Pro {
        width: 280px;
        height: 530px;
    }

    .Swiper_Slide_Image {
        width: 100%;
    }
}

@media(max-width:820px) {
    .iPhone_15_Pro {
        width: 265px;
        height: 530px;
    }

    .Swiper_Slide_Image {
        width: 100%;
    }
}

@media(max-width:768px) {
    .iPhone_15_Pro {
        width: 255px;
        height: 520px;
    }

    .Swiper_Slide_Image {
        width: 100%;
    }
}

@media(max-width:540px) {
    .iPhone_15_Pro {
        width: 285px;
        height: 530px;
    }

}

@media(max-width:440px) {
    .iPhone_15_Pro {
        width: 305px;
        height: 530px;
    }

    .Swiper_Slide_Image {
        width: 100%;
    }
}

@media(max-width:414px) {
    .iPhone_15_Pro {
        width: 296px;
        height: 520px;
    }

    .Swiper_Slide_Image {
        width: 100%;
    }

}

@media(max-width:390px) {
    .iPhone_15_Pro {
        width: 280px;
        height: 510px;
    }

    .Swiper_Slide_Image {
        width: 100%;
    }
}

@media(max-width:375px) {
    .iPhone_15_Pro {
        width: 267px;
        height: 513px;
    }

    .Swiper_Slide_Image {
        width: 100%;
    }
}

@media(max-width:360px) {
    .iPhone_15_Pro {
        width: 257px;
        height: 520px;
    }

    .Swiper_Slide_Image {
        width: 100%;
    }
}

@media(max-width:344px) {
    .iPhone_15_Pro {
        width: 245px;
        height: 500px;
    }

    .Swiper_Slide_Image {
        width: 100%;
    }
}